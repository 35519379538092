<template>
  <div>
    <b-button v-b-modal.modal-lg variant="primary">Tambah data</b-button>

    <b-modal id="modal-lg" size="xl" hide-footer header-class="align-items-center" @hide="resetSelection">
      <template #modal-header="{ close }">
        <h2 class="text-2xl font-semibold mb-4">Tambah Data</h2>

        <b-button variant="text" @click="close()"
          class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
          style="min-height: 24px">
          <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
        </b-button>
      </template>

      <!-- Pilihan tipe transaksi -->
      <b-form-group label="Pilih Tipe Transaksi" label-for="transaction-type" class="mb-5">
        <b-form-select id="transaction-type" v-model="selectedType" :options="transactionOptions" class="w-full"
          required></b-form-select>
      </b-form-group>

      <!-- Form berdasarkan pilihan tipe transaksi -->
      <component :is="currentFormComponent" v-if="currentFormComponent" @submit="handleFormSubmit"></component>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import FormUangMasuk from "./FormUangMasuk.vue";
import FormUangKeluar from "./FormUangKeluar.vue";
import FormPindahSaldo from "./FormPindahSaldo.vue"; // Corrected name

export default {
  name: "AddDataModal",
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormSelect,
    FormUangMasuk,
    FormUangKeluar,
    FormPindahSaldo,
  },
  data() {
    return {
      selectedType: "", // Default tipe transaksi
      transactionOptions: [
        { value: "", text: "Pilih Tipe Transaksi" },
        { value: "masuk", text: "Uang Masuk" },
        { value: "keluar", text: "Uang Keluar" },
        { value: "pindah", text: "Pindah Saldo" },
      ],
    };
  },
  computed: {
    currentFormComponent() {
      switch (this.selectedType) {
        case "masuk":
          return "FormUangMasuk";
        case "keluar":
          return "FormUangKeluar";
        case "pindah":
          return "FormPindahSaldo"; // Corrected name
        default:
          return null;
      }
    },
  },
  methods: {
    resetSelection() {
      this.selectedType = "";
    },
    handleFormSubmit({ type, data }) {
      // Combine with additional data if needed
      const formData = {
        tipe: type,
        ...data,
      };
      console.log(`Data disimpan untuk ${type}:`, formData);
      this.$bvModal.hide("modal-lg");
      // Here you can emit an event or perform further actions with formData
    },
  },
};
</script>

<style scoped>
/* Optional: Add component-specific styles here */
</style>
