<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Akun Asal" label-for="akun-asal" class="mb-4">
      <b-form-input id="akun-asal" v-model="form.asal" type="text" placeholder="Masukkan akun asal" required />
    </b-form-group>
    <b-form-group label="Akun Tujuan" label-for="akun-tujuan" class="mb-4">
      <b-form-input id="akun-tujuan" v-model="form.tujuan" type="text" placeholder="Masukkan akun tujuan" required />
    </b-form-group>
    <b-form-group label="Nominal" label-for="nominal-pindah" class="mb-4">
      <b-form-input id="nominal-pindah" v-model="form.nominal" type="number" placeholder="Masukkan nominal" required />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button type="submit" variant="primary">Simpan</b-button>
    </div>
  </b-form>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  name: "FormPindahSaldo",
  data() {
    return {
      form: {
        asal: "",
        tujuan: "",
        nominal: null,
      },
    };
  },
  methods: {
    onSubmit() {
      // Emit the submit event with type and form data
      this.$emit("submit", { type: "pindah", data: { ...this.form } });
      this.resetForm();
    },
    resetForm() {
      this.form.asal = "";
      this.form.tujuan = "";
      this.form.nominal = null;
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
