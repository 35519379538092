<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Nominal" label-for="nominal-masuk" class="mb-4">
      <b-form-input id="nominal-masuk" v-model="form.nominal" type="number" placeholder="Masukkan nominal" required />
    </b-form-group>
    <b-form-group label="Sumber Uang" label-for="sumber" class="mb-4">
      <b-form-input id="sumber" v-model="form.sumber" type="text" placeholder="Masukkan sumber uang" required />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button type="submit" variant="primary">Simpan</b-button>
    </div>
  </b-form>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  name: "FormUangMasuk",
  data() {
    return {
      form: {
        nominal: null,
        sumber: "",
      },
    };
  },
  methods: {
    onSubmit() {
      // Emit the submit event with type and form data
      this.$emit("submit", { type: "masuk", data: { ...this.form } });
      this.resetForm();
    },
    resetForm() {
      this.form.nominal = null;
      this.form.sumber = "";
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
