<template>
  <BaseLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-start">
        <h2>Riwayat Transaksi</h2>
        <FormModal />
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="row align-items-center" style="gap: 15px;">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua (5)
          </p>
          <b-form-group label="Jenis Kas" label-for="filter-by-type-cash" class="mb-0">
            <b-form-select id="filter-by-type-cash" />
          </b-form-group>
          <b-form-group label="Tanggal" label-for="filter-by-date" class="mb-0">
            <b-form-select id="filter-by-date" />
          </b-form-group>
          <b-form-group label="Bulan" label-for="filter-by-month" class="mb-0">
            <b-form-select id="filter-by-month" />
          </b-form-group>
          <b-form-group label="Tahun" label-for="filter-by-year" class="mb-0">
            <b-form-select id="filter-by-year" />
          </b-form-group>
          <b-form-group label="Tahun" label-for="filter-by-year" class="mb-0">
            <b-form-select v-model="selectedYear" :options="yearOptions" @change="updateMonthOptions"
              id="filter-by-year" />
          </b-form-group>
          <b-form-group label="Bulan" label-for="filter-by-month" class="mb-0">
            <b-form-select v-model="selectedMonth" id="filter-by-month" :options="monthOptions" />
          </b-form-group>
          <b-form-group label="Tanggal" label-for="filter-by-date" class="mb-0">
            <b-form-select v-model="selectedDay" :options="dayOptions" id="filter-by-date" />
          </b-form-group>
        </div>
        <div class="w-15 align-items-center">
          <div class="position-relative">
            <b-input-group class="hp-input-group-search custom-input-height">
              <b-form-input type="search" placeholder="Cari Berdasarkan Kode"
                class="custom-input-height search-input" />
              <i class="ri-search-line remix-icon text-primary search-icon" />
            </b-input-group>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <b-table :fields="fields" :items="transactions" striped hover responsive>
        <template #cell(price)="data">
          Rp{{ formatCurrency(data.item.price) }}
        </template>
        <template #cell(total_price)="data">
          Rp{{ formatCurrency(data.item.total_price) }}
        </template>
      </b-table>
      <b-pagination class="mb-3" align="right" />
    </template>
  </BaseLayout>
</template>
<script>
import FormModal from './components/FormModal.vue';
import { BFormGroup, BFormSelect, BInputGroup, BFormInput, BButton, BTable, BPagination } from 'bootstrap-vue'

export default {
  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      yearOptions: this.getYearOptions(),
      monthOptions: [],
      dayOptions: Array.from({ length: 31 }, (_, i) => ({ value: i + 1, text: i + 1 })),
      fields: [
        {
          label: 'No',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Tanggal',
          key: 'date',
          sortable: true,
        },
        {
          label: 'Tipe',
          key: 'type',
        },
        {
          label: 'Nama Transaksi',
          key: 'transaction_name',
        },
        {
          label: 'Kuantitas',
          key: 'qty',
        },
        {
          label: 'Satuan',
          key: 'satuan',
        },
        {
          label: 'Harga',
          key: 'price',
        },
        {
          label: 'Total Harga',
          key: 'total_price',
        },
      ],
      transactions: [
        {
          id: 1,
          date: '01/01/2024',
          type: 'Cash ATM',
          transaction_name: 'Aneka Jajanan Pasar',
          qty: 10,
          satuan: 'Pack',
          price: 100000,
          total_price: 1000000,
        },
        {
          id: 2,
          date: '01/01/2024',
          type: 'Cash ATM',
          transaction_name: 'Aneka Jajanan Pasar',
          qty: 10,
          satuan: 'Pack',
          price: 100000,
          total_price: 1000000,
        },
        {
          id: 3,
          date: '01/01/2024',
          type: 'Cash ATM',
          transaction_name: 'Aneka Jajanan Pasar',
          qty: 10,
          satuan: 'Pack',
          price: 100000,
          total_price: 1000000,
        },
        {
          id: 4,
          date: '01/01/2024',
          type: 'Cash ATM',
          transaction_name: 'Aneka Jajanan Pasar',
          qty: 10,
          satuan: 'Pack',
          price: 100000,
          total_price: 1000000,
        },
        {
          id: 5,
          date: '01/01/2024',
          type: 'Cash ATM',
          transaction_name: 'Aneka Jajanan Pasar',
          qty: 10,
          satuan: 'Pack',
          price: 100000,
          total_price: 1000000,
        },
      ],
    }
  },
  components: {
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BPagination,
    BButton,
    FormModal,
    BTable,
  },
  methods: {
    getYearOptions() {
      const currentYear = new Date().getFullYear();

      return Array.from({ length: 10 }, (_, i) => ({ value: currentYear - i, text: currentYear - i }))
    },
    updateMonthOptions() {
      if (this.selectedYear !== null) {
        const isCurrentYear = this.selectedYear === new Date().getFullYear()
        const currentMonth = new Date().getMonth() + 1;

        const months = [
          { value: 1, text: 'January' },
          { value: 2, text: 'February' },
          { value: 3, text: 'March' },
          { value: 4, text: 'April' },
          { value: 5, text: 'May' },
          { value: 6, text: 'June' },
          { value: 7, text: 'July' },
          { value: 8, text: 'August' },
          { value: 9, text: 'September' },
          { value: 10, text: 'October' },
          { value: 11, text: 'November' },
          { value: 12, text: 'December' },
        ];

        this.monthOptions = isCurrentYear ? months.slice(0, currentMonth) : months
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID').format(value)
    }
  }
}

</script>

<style scoped>
/* kostumisasi ukuran dari input search */
.custom-select-height,
.custom-input-height {
  height: 38px;
}

.custom-button-height {
  height: 100%;
}

.hp-input-group-search {
  display: flex;
  align-items: center;
}

/* posisi relatif untuk menempatkan icon pencarian kedalam input */
.position-relative {
  position: relative;
}

.search-input {
  width: 100%;
  padding-left: 30px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #007bff;
}
</style>