<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Nominal" label-for="nominal-keluar" class="mb-4">
      <b-form-input id="nominal-keluar" v-model="form.nominal" type="number" placeholder="Masukkan nominal" required />
    </b-form-group>
    <b-form-group label="Keperluan" label-for="keperluan" class="mb-4">
      <b-form-input id="keperluan" v-model="form.keperluan" type="text" placeholder="Masukkan keperluan" required />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button type="submit" variant="primary">Simpan</b-button>
    </div>
  </b-form>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  name: "FormUangKeluar",
  data() {
    return {
      form: {
        nominal: null,
        keperluan: "",
      },
    };
  },
  methods: {
    onSubmit() {
      // Emit the submit event with type and form data
      this.$emit("submit", { type: "keluar", data: { ...this.form } });
      this.resetForm();
    },
    resetForm() {
      this.form.nominal = null;
      this.form.keperluan = "";
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
